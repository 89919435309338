import React from 'react';
import manuals from '../../assets/img/documents_downloads/manuals.png';
import brochures from '../../assets/img/documents_downloads/brochures.png';
import wiring from '../../assets/img/documents_downloads/wiring.png';
import installation from '../../assets/img/documents_downloads/installation.png';
import cadDrawing from '../../assets/img/documents_downloads/cad-drawings.png';

function DocumentsAndDownloads() {
  return(
    <>
      <section className='section-5_2'>
        <div className='container'>
          <div className='section-4-header pt-5'>
            <h3 className='section-header'>Documents & Downloads</h3>
          </div>
          <div className='row mt-5 pb-3'>
            <div className='col'>
              <div class="card">
                <img src={manuals} class="card-img-top" alt="Manuals Icon" />
                <div class="card-body">
                  <h5 class="card-title">Manuals</h5>
                </div>
              </div>
            </div>
            <div className='col'>
              <div class="card">
                <img src={brochures} class="card-img-top" alt="Brochures Icon" />
                <div class="card-body">
                  <h5 class="card-title">Brochures</h5>
                </div>
              </div>
            </div>
            <div className='col'>
              <div class="card">
                <img src={wiring} class="card-img-top" alt="Wiring Diagrams Icon" />
                <div class="card-body">
                  <h5 class="card-title">Wiring Diagrams</h5>
                </div>
              </div>
            </div>
            <div className='col'>
              <div class="card">
                <img src={installation} class="card-img-top" alt="Installations Icon" />
                <div class="card-body">
                  <h5 class="card-title">Installations</h5>
                </div>
              </div>
            </div>
            <div className='col'>
              <div class="card">
                <img src={cadDrawing} class="card-img-top" alt="CAD Drawings Icon" />
                <div class="card-body">
                  <h5 class="card-title">CAD Drawings</h5>
                </div>
              </div>
            </div>
            <div className='section-3' style={{ 'padding': '0'}}>
              <div className='text-center' style={{ 'background': '#F8FAFD'}}>
                <a href="/support/downloads" className='btn btn-primary'>View All Downloads</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DocumentsAndDownloads;